@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 100;
    src: url(../public//fonts/Pretendard-Thin.otf) format('opentype');
  }

  @font-face {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 200;
    src: url(../public//fonts/Pretendard-ExtraLight.otf) format('opentype');
  }

  @font-face {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 300;
    src: url(../public//fonts/Pretendard-Light.otf) format('opentype');
  }

  @font-face {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 400;
    src: url(../public//fonts/Pretendard-Regular.otf) format('opentype');
  }

  @font-face {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 500;
    src: url(../public//fonts/Pretendard-Medium.otf) format('opentype');
  }

  @font-face {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 600;
    src: url(../public//fonts/Pretendard-SemiBold.otf) format('opentype');
  }

  @font-face {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 700;
    src: url(../public//fonts/Pretendard-Bold.otf) format('opentype');
  }

  @font-face {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 800;
    src: url(../public//fonts/Pretendard-ExtraBold.otf) format('opentype');
  }

  @font-face {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 900;
    src: url(../public//fonts/Pretendard-Black.otf) format('opentype');
  }

  html {
    font-family: 'Pretendard';
    color: #1d1d1f;
  }
}

html {
  font-size: 14px;
}

@media (max-width: 768px) {
  html {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  html {
    font-size: 10px;
  }
}
